.buttonContainer {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  top: 160px;
  right: 10px;
  bottom: auto;
  left: auto;
}

.button {
  background-color: #f9f9f9;
  opacity: 0.95;
  transition: background-color 0.16s ease-out;
  cursor: pointer;
  border: 0;
  height: 26px;
  width: 26px;
  outline: 0;
  padding: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background-color: #fff;
  opacity: 1;
}
