.iconsContainer {
  min-height: 100%;
  position: relative;
  padding-top: calc(var(--menu-height) + 15px);
  color: var(--dark-cs);
  background-color: white;
  h1 {
    font-weight: normal;
  }

  p {
    font-size: 1.25rem;
  }
}

.metadata {
  text-transform: uppercase;
  color: var(--red-cs);
  font-weight: normal;
  margin-bottom: 0px;
  letter-spacing: 1px;
  font-size: 0.875rem;
}

.stickyAxis {
  z-index: 2;
  position: sticky;
  top: var(--menu-height);
  background-color: white;
}
