$icons-width: 56px;
:export {
  iconsWidth: $icons-width;
}

.name {
  text-transform: capitalize;
  a,
  a:hover {
    color: var(--dark-cs);
    text-decoration: none;
    border-bottom: 2px solid var(--dark-cs);
  }
  margin-bottom: 20px;
  margin-top: 15px;
  font-size: 1.5rem;
  font-weight: normal;
}

.metadata {
  h6 {
    text-transform: uppercase;
    color: var(--red-cs);
    font-weight: normal;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }
  padding-bottom: 15px;
}

.iconContainer {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.iconImage {
  flex-grow: 0;
  flex-shrink: 0;
}

.image {
  border-radius: 100%;
  height: $icons-width;
  width: $icons-width;
  object-fit: cover;
  transition: border-radius var(--transition);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.itemFallback {
  border-radius: 100%;
  height: $icons-width;
  width: $icons-width;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: border-radius var(--transition);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.iconTitle {
  margin-left: 10px;
  margin-right: 10px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .desc {
    font-size: 0.875rem;
    font-family: "Inter", sans-serif;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      color: var(--dark-cs);
      text-decoration: underline;
    }
  }
}

.inceptionLine {
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 1px dashed rgba(0, 0, 0, 0.5);
}
