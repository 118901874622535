.themesContainer {
  height: 100%;
  color: var(--dark-cs);
  background-color: white;
  position: relative;
  padding-top: calc(var(--menu-height) + 15px);
  h1 {
    font-weight: normal;
  }
}

.pageText {
  p {
    font-size: 1.25rem;
  }
}

.storiesTitle {
  text-transform: uppercase;
  color: var(--red-cs);
  font-weight: normal;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.storyTitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  padding-left: 15px;
}

.reset {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  text-decoration: underline;
  margin-left: auto;
}

.plus {
  color: var(--red-cs);
}

.link {
  color: var(--dark-cs);
  text-decoration: none;
}

.link:hover {
  color: var(--dark-cs);
}

.rowFill {
  flex: 1 1 1px;
}

.storiesScroll {
  flex: 1 1 1px;
  overflow-y: scroll;
}
