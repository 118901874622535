.image {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: left;
}

.playerWrapper {
  height: 100%;
  flex: 1 1;
  overflow: hidden;
  padding: 15px 0;
}

.reactPlayer {
  background-color: black;
}
