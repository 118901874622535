@import url("https://rsms.me/inter/inter.css");
@import url("https://indestructibletype.com/fonts/Besley.css");

@font-face {
  font-family: "CeraStencilPRO";
  src: url("./fonts/CeraStencilPRO-Regular.eot"),
    url("./fonts/CeraStencilPRO-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/CeraStencilPRO-Regular.woff") format("woff"),
    url("./fonts/CeraStencilPRO-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CeraStencilPRO";
  src: url("./fonts/CeraStencilPRO-Bold.eot"),
    url("./fonts/CeraStencilPRO-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/CeraStencilPRO-Bold.woff") format("woff"),
    url("./fonts/CeraStencilPRO-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@import "variables";
@import "~bootstrap/scss/bootstrap";

:root {
  --dark-cs: #101012;
  // --red-cs: #009ee3;
  --red-cs: #009ee3;
  --gray-cs: #d0d0d0;
  --transition: 0.3s ease-in-out;
  --menu-height: 60px;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: var(--dark-cs);
}

.carousel-inner {
  height: 100%;
}
