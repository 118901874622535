.audioContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.objectContentAudio {
  padding: 0;
  margin: 15px 0;
  width: 100%;
}

.audioControlButton {
  background: white;
  width: 40px;
  height: 40px;
  margin: 5px;
  border: 1px solid black;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
