.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  pointer-events: none;
}

.top {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.introStep {
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  p {
    width: 50%;
    font-size: 1.6rem;
    text-align: center;
  }
}

.svgContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.carouselItem {
  height: 100%;
}

.nextButton {
  align-self: center;
  border-radius: 999px;
  border: 1px solid white;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  animation: fillingHtml 1s linear 2s forwards;
  pointer-events: all;
}

.skip {
  margin-top: auto;
  margin-bottom: 15px;
  opacity: 0;
  pointer-events: all;

  a {
    font-family: "Inter", sans-serif;
    color: white;
    text-decoration: underline;
  }

  a:hover {
    color: white;
    text-decoration: underline;
  }

  animation: fillingHtml 1s linear 2s forwards;
}

.svg {
  width: 100%;
  // height: 100%;
}

.title {
  font-size: 5rem;
  font-family: "CeraStencilPRO", sans-serif;
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  stroke: var(--red-cs);
  stroke-width: 2px;
  fill-opacity: 0;
  fill: var(--red-cs);
  animation: dash 10s linear forwards, filling 1s linear 9s forwards;
}

@keyframes dash {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {
  100% {
    fill-opacity: 1;
    stroke-width: 0px;
  }
}

@keyframes fillingHtml {
  100% {
    opacity: 1;
  }
}
