$icons-width: 56px;

.cont {
  height: $icons-width + 20px;
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.iconTranslate {
  position: absolute;
  z-index: 1;
}

.iconTranslateHover {
  z-index: 2;
}

.iconContainer {
  display: flex;
  transform: translateX(-$icons-width/2);
  opacity: 1;
  transition: opacity var(--transition);
}

.iconImage {
  flex-grow: 0;
  flex-shrink: 0;
}

.image {
  border-radius: 100%;
  height: $icons-width;
  width: $icons-width;
  object-fit: cover;
  transition: border-radius var(--transition);
  border: 1px solid black;
}

.itemFallback {
  border-radius: 100%;
  height: $icons-width;
  width: $icons-width;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid black;
}

.iconTitle {
  margin-left: 10px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .desc {
    font-size: 0.875rem;
    font-family: "Inter", sans-serif;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.inceptionLine {
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 1px dashed rgba(0, 0, 0, 0.5);
}
