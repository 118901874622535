.menuContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: var(--menu-height);
  border-bottom: 1px solid white;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.85) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  transition: all var(--transition);
}

.menuContainer.light {
  border-bottom: 1px solid #d0d0d0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.title {
  text-transform: uppercase;
  font-family: "CeraStencilPRO", sans-serif;
  font-size: 1.75rem;
  border-right: 1px solid white;
  padding-right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  a,
  a:hover {
    color: var(--red-cs);
    text-decoration: none;
  }
}

.light .title {
  border-right: 1px solid #d0d0d0;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.menuButtons {
  padding: 0 15px;
  border-right: 1px solid white;
  height: 100%;
  display: flex;
  align-items: center;
  a {
    font-family: "Inter", sans-serif;
    color: white;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    font-size: 1.375rem;
    transition: all var(--transition);
  }

  a:hover {
    color: white;
    text-decoration: none;
    border-bottom: 2px solid white;
  }

  a.secondary {
    font-family: "Besley", serif;
    font-style: italic;
  }
}

.light .menuButtons {
  border-right: 1px solid #d0d0d0;

  a {
    color: var(--dark-cs);
  }

  a:hover {
    color: var(--dark-cs);
    border-bottom: 2px solid var(--dark-cs);
  }
}

.menuButtons.about {
  padding-right: 0px;
  border-right: none;
  border-left: 1px solid white;
}

.light .menuButtons.about {
  border-left: 1px solid #d0d0d0;
}
