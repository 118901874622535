.label {
  fill: var(--dark-cs);
  alignment-baseline: middle;
  text-anchor: middle;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  pointer-events: none;
  transition: all var(--transition);
  paint-order: stroke;
  stroke: #fff;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: bevel;
}

.background {
  fill: #fff;
  stroke: #fff;
  stroke-width: 5px;
  transition: all var(--transition);
  pointer-events: none;
}
