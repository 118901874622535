.iconContainer {
  height: 100%;
  width: 100%;
  padding-top: 50px;
  color: var(--dark-cs);
  background-color: white;
}

.rowFill {
  flex: 1 1 1px;
}

.title {
  font-family: "Besley", serif;
  font-weight: normal;
  font-size: 2.25rem;
}

.subtitle {
  font-weight: normal;
  margin: 0;

  a {
    color: black;
    text-decoration: underline;
  }
}

.circleButton {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid black;
  color: black;
}

.circleButton:hover {
  color: black;
}

.metadata {
  text-transform: uppercase;
  color: var(--red-cs);
  font-weight: normal;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.descriptionContainer {
  flex: 1 1 1px;
  overflow: hidden;
  margin: 15px 0;
  position: relative;
  display: flex;
}

.descriptionContainer:after {
  content: "";
  pointer-events: none;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.description {
  font-size: 1.125rem;
  text-indent: 30px;
}

.fileContainer {
  flex: 1 1 1px;
  overflow-y: scroll;
}

.fileViewerContainer {
  max-height: 60%;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}

.storylinesContainer {
  margin: 20px 0px;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  color: black;
}

.plus {
  color: var(--red-cs);
}

.storyTitle {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1.125rem;
  line-height: 1.2;
  padding-left: 15px;
}
