.stripes {
  top: 0px;
  bottom: 0px;
  left: 15px;
  right: 15px;
  position: absolute;
  display: flex;
}
.stripe:nth-child(odd) {
  background-color: transparent;
}

.stripe:nth-child(even) {
  background-color: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
}
