.wrapper {
  padding-left: 36px;
  background-color: rgba(204, 204, 204, 0.5);
  overflow: hidden;
}

.resourceContainer,
.hyperlinkContainer,
.referenceContainer {
  border-bottom: 1px solid white;
}

.resourceContainer,
.hyperlinkContainer {
  display: flex;
}

.resourceContainer > div:not(:last-of-type),
.referenceContainer > div:not(:last-of-type),
.hyperlinkContainer > div:not(:last-of-type) {
  padding: 20px 0;
}

.dotNumber {
  flex: 0 0 22px;
}

.number {
  width: 18px;
  height: 18px;
  margin: 2px;
  border-radius: 100%;
  background-color: var(--red-cs);
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  color: white;
  text-align: center;
}

.linkedPages {
  flex: 0 0 30px;
  background-color: white;
  position: relative;
}

.linkedArrow {
  transform: rotate(0deg);
  transition: transform var(--transition);
}

.linkedArrow.rotated {
  transform: rotate(180deg);
}

.linkedButton {
  width: 30px;
  height: 60px;
  background-color: var(--red-cs);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.linkedPagesContainer {
  background: white;
  position: absolute;
  width: 350px;
  top: 0;
  min-height: 60px;
  right: 30px;
  z-index: 1;
  padding: 6px;
  transform: translateX(calc(100% + 30px));
  transition: transform var(--transition);
}

.linkedPagesContainer.open {
  transform: translateX(0%);
}

.plus {
  color: var(--red-cs);
}

.referenceContainer {
  display: flex;
}

.fileContainer {
  width: 100%;

  img {
    max-height: 40vh;
    width: 100%;
    object-fit: contain;
    object-position: left;
  }
}

.infoContainer {
  display: flex;
  margin-top: 10px;
}

.caption {
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
  font-size: 0.875rem;

  a {
    color: black;
    text-decoration: underline;
  }
}

.link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  color: var(--dark-cs);
  text-decoration: underline;
  display: block;
}

.link:hover {
  color: var(--dark-cs);
  text-decoration: underline;
}

.cite {
  font-family: "Inter", sans-serif;
  margin-bottom: 0;
  margin-left: 0.25rem;
  font-size: 0.875rem;
}

.linked {
  flex: 0 0 50%;
  max-width: 50%;
}

.metadata {
  color: var(--red-cs);
  font-weight: normal;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.storyTitle {
  margin: 3px 0 0 0;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
}

.linkStory {
  color: black;
  text-decoration: none;
}

.linkStory:hover {
  color: black;
}

.hyperlinkEllipsis {
  min-width: 0;
}
