.storyContainer {
  height: 100%;
  color: var(--dark-cs);
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: calc(var(--menu-height));
}

.rowFill {
  flex: 1 1 1px;
}

.titleContainer {
  padding: 15px 0;
  border-bottom: solid #d0d0d0 1px;

  h2 {
    font-family: "Besley", serif;
    font-size: 2rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin: 5px 0;
  }

  .campLink {
    font-weight: normal;
    margin: 0;

    a {
      color: black;
      text-decoration: underline;
    }
  }

  .authorsCont {
    font-size: 0.875rem;
    font-family: "Inter", sans-serif;
    margin-top: 5px;
  }

  .authorsLabel {
    color: var(--red-cs);
    text-transform: uppercase;
    font-size: 0.75rem;
    opacity: 0.5;
  }
}

.vertLabel {
  position: absolute;
  top: 22px;
  left: 8px;
  z-index: 1;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
  opacity: 0.5;

  h6 {
    color: var(--red-cs);
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.875rem;
    margin: 0;
  }
}

.storyTag {
  background: white;
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  text-transform: capitalize;
  color: var(--red-cs);
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 100px;
  margin-right: 5px;
  border: 1px solid var(--red-cs);
}

.storyTag:hover {
  color: white;
  text-decoration: none;
  background: var(--red-cs);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  // height: 100%;
  flex: 1 1 1px;
}

.paragraphs {
  flex: 1 1 1px;
  padding: 20px 0 20px 1rem;
  overflow-y: auto;

  p {
    text-indent: 30px;
    font-size: 1rem;
  }
}

.paragraphs div:last-child {
  min-height: 100%;
}

.dots {
  width: 100%;
  padding: 20px 0px;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .dotsRect {
    width: 20px;
    background: #ccc;
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    transition: opacity var(--transition);
    flex: 1 1 10px;
    overflow: hidden;
    border-radius: 10px;
    .dot {
      width: 8px;
      height: 8px;
      border-radius: 10px;
      background: #009ee3;
    }
  }
}
.attachments {
  overflow-y: auto;
  flex: 1 1 1px;
}
