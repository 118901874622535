.notFoundContainer {
  height: 100%;
  position: relative;
  padding-top: calc(var(--menu-height) + 15px);
  color: white;
  h1 {
    font-weight: normal;
  }

  p {
    font-size: 1.25rem;
  }

  a {
    color: var(--red-cs);
  }
}
