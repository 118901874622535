.svgNetwork {
  overflow: visible;
}

.svgNetwork a:hover {
  text-decoration: none;
}

.zoomControls {
  position: absolute;
  top: 0px;
  right: 30px;
}
