.campContainer {
  position: relative;
  color: var(--dark-cs);
  background-color: white;
  min-height: 100%;
}

.topContainer {
  height: 65vh;
  padding-top: var(--menu-height);
  position: relative;
}

.infoContainer {
  padding-top: 15px;
}

.iconsContainer {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 25px;
  position: relative;
}

.vertLabel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  z-index: 1;

  h6 {
    color: var(--red-cs);
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.875rem;
    margin: 0;
  }
}

.vertLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  z-index: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ddContainer {
  padding: 15px 0px;
  position: relative;
}

.titleWrapper {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: relative;
  padding-bottom: 20px;
  pointer-events: none;
}
.title {
  font-weight: normal;
  text-transform: capitalize;
  margin-top: 20px;
  pointer-events: all;
  color: white;
}

.metadata {
  h6 {
    text-transform: uppercase;
    color: var(--red-cs);
    font-weight: normal;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }
  padding-bottom: 15px;
}

.externlLink {
  margin-bottom: 2px;

  a {
    color: black;
    text-decoration: underline;
  }

  a:hover {
    color: black;
  }
}

.descriptionContainer {
  // max-height: 600px;
  display: flex;
  position: relative;
}

.descriptionContainer:after {
  content: "";
  pointer-events: none;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  // background: linear-gradient(
  //   0deg,
  //   rgba(16, 16, 18, 1) 0%,
  //   rgba(16, 16, 18, 0) 100%
  // );
}

.description {
  font-size: 1.25rem;
  padding-bottom: 50px;
}

.dropdown {
  display: flex;
  align-items: center;
}

.dropdownLabel {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
  margin-right: 10px;
}

.networkContainer {
  width: 100%;
  height: calc(100vh - var(--menu-height));
  display: flex;
  flex-direction: column;
}
