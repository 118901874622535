.ThemesCircles {
  width: 100%;
  height: 100%;

  .circle {
    fill: #fff;
    stroke: var(--red-cs);
    cursor: pointer;
    transition: fill var(--transition);
  }

  .circle:hover,
  .circle.active {
    fill: var(--red-cs);
  }

  .circle:hover + rect,
  .circle.active + rect {
    fill: #fff;
    stroke: #fff;
  }

  .circle:hover + text {
    fill: var(--dark-cs);
  }

  .circle.active + text {
    fill: var(--red-cs);
  }
}
