.dd {
  font-family: "Inter", sans-serif;
  button {
    text-transform: uppercase !important;
  }
}

.dropdownItem:active {
  background-color: var(--red-cs) !important;
}
