.aboutContainer {
  min-height: 100%;
  position: relative;
  padding-top: calc(var(--menu-height) + 15px);
  color: var(--dark-cs);
  background-color: white;
  h1 {
    font-weight: normal;
  }

  p {
    font-size: 1.25rem;
  }
}
